export default {
    name: 'CreditWallet',
    props: ['promoAmount', 'promoDiscount', 'regularPrice', 'discountedPrice'],
    data() {
        return {
            available: null,
        }
    },
    computed: {
        user() {
            return this.$store.state.user.info;
        },
    },
}