<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{  'ad-type-premium': adtype === 'premium',
    'ad-type-regular': adtype === 'regular',
  }">
    <div class="selection-ad-container row cursor-pointer">
      <div class="col-1 ">
        <input type="radio" v-bind:checked="selected" @change="onChange">
      </div>
      <div class="text-container col col-md-8">
        <div class="">
          <div class="selection-title" v-if="adtype === 'start'">Start - {{ $t('listing-for') }} 28 {{ $t('days') }}</div>
          <div class="selection-title" v-if="adtype === 'premium'"><span class="premium-span">Premium</span> - {{ $t('listing-for') }}
            {{ days }} {{ $t('days') }}
          </div>
          <div class="selection-title" v-if="adtype === 'exclusive'"><span class="premium-span">Professional</span> - {{ $t('listing-for') }} {{ days }} {{ $t('days') }}
          </div>
          <div class="selection-title" v-if="adtype === 'premium-30-plus'">
            <span class="premium-span">Exclusive</span> - {{ $t('listing-for') }} {{ days }} {{ $t('days') }}
          </div>
          <div class="selection-para">
            {{ description }}
          </div>
        </div>
      </div>
      <div class="col-md-3 credits-desktop">
        <div class="row svg-container" v-if="adtype === 'start'">
          <div class="col-2 col-md-5">
            <LabelFree/>
          </div>
          <div class="col-8 col-md-7 selection-subtitle">{{ $t('free-subtitle') }}</div>
        </div>
        <div class="row svg-container" v-if="adtype === 'premium' || adtype === 'exclusive' || adtype === 'premium-30-plus'">
          <div class="col-2 col-md-2">
            <CoinsIcon/>
          </div>
          <div class="col-8 col-md-10 selection-subtitle">
            {{ amount }} {{ $t('credits') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
